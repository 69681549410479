$primary-color: #FFCC00;
$dark-color: #19212e;
$input-border-color: #4F4F4F;
$dark-color-2: #171717;
$white: #ffffff;
$black: #000000;
$transparent: #EFECEC7A;
$text-primary: #EBEBEB;
$text-color-2: #BEBEBE;
$header-bg-color: #171717;
$green: #27AE60;
$btn-primary-color: #27348B;
$bg-gray: #DCDCDC;
$back-button-color: #3390FF;
